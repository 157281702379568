import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../firebase/authContext";

const NavBar = ({ isNavOpen, toggleNav }) => {
  const { logout } = useAuth();

  const pages = [
    { path: "/dashboard/users", name: "Users" },
    { path: "/dashboard/users/codes", name: "Referral Codes" },
    { path: "/dashboard/usersTable", name: "Users Table" },
    { path: "/dashboard/verifications", name: "ID Verifications" },
    { path: "/dashboard/deletion-requests", name: "Deletion Requests" },
    { path: "/dashboard/administrators", name: "Administrators" },
    { path: "/dashboard/rides", name: "Rides" },
    { path: "/dashboard/ridesList", name: "Rides List" },
    { path: "/dashboard/rides/activeRidesMap", name: "Active Rides Map" },
    { path: "/details/groupsList", name: "Group List" },
  ];

  return (
    <nav
      className={`fixed top-0 left-0 h-full bg-gray-800 text-white flex flex-col justify-between transition-transform duration-300 ${
        isNavOpen ? "translate-x-0" : "-translate-x-full"
      }`}
      style={{ width: "16rem" }} // 16rem is 256px
    >
      <div className="flex justify-end p-2">
        <button
          className="p-2 bg-gray-700 text-white rounded"
          onClick={toggleNav}
        >
          Hide Menu
        </button>
      </div>
      <ul className="p-4">
        {pages.map(({ path, name }) => (
          <li className="mb-2" key={path}>
            <NavLink
              to={path}
              className="block py-2 px-4 rounded hover:bg-gray-700"
              activeClassName="bg-gray-700"
            >
              {name}
            </NavLink>
          </li>
        ))}
      </ul>

      <button
        className="block py-2 px-4 m-4 rounded hover:bg-gray-700 self-center"
        onClick={logout}
      >
        Logout
      </button>
    </nav>
  );
};

export default NavBar;
