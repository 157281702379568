import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import UserCard from "../components/UserCard";
import {
  getDeletionRequests,
  getDataFromUid,
  deleteUserFromStorage,
  deleteUserFromAuth,
  deleteUserFromDB,
  cancelDeleteRequest,
  removeFromRejectedUsers,
  addToDB,
} from "../firebase/dbFunctions";

const DeletionRequestsPage = () => {
  const [requests, setRequests] = useState({});
  const [usersData, setUsersData] = useState({});
  const [filterByAdmin, setFilterByAdmin] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const requestsData = await getDeletionRequests();
        setRequests(requestsData);

        // Fetch detailed user data for each request
        const usersDataPromises = Object.keys(requestsData).map(async (uid) => {
          const userData = await getDataFromUid(uid);
          return { uid, userData };
        });

        const usersDataArray = await Promise.all(usersDataPromises);
        const usersDataObject = usersDataArray.reduce(
          (acc, { uid, userData }) => {
            acc[uid] = userData;
            return acc;
          },
          {}
        );

        setUsersData(usersDataObject);
      } catch (error) {
        console.error("Error fetching deletion requests or user data:", error);
      }
    };

    fetchRequests();
  }, []);

  const toggleNav = () => setIsNavOpen(!isNavOpen);

  const handleDeleteUser = async (uid) => {
    const action = window.prompt(
      "Type 'delete' to delete the user, 'cancel' to cancel the deletion request, or anything else to close this prompt."
    );
    // if (action === "test") {
    //   // Usage example
    //   const uid = "some-unique-uid";
    //   await addToDB("deletedUIDs", { [uid]: new Date().toISOString() });
    // }

    if (action === "delete") {
      try {
        await deleteUserFromStorage(uid);
        await deleteUserFromAuth(uid);
        await deleteUserFromDB(uid);
        await cancelDeleteRequest(uid);

        if (
          usersData[uid] &&
          usersData[uid].private_info &&
          usersData[uid].private_info.id_status
        ) {
          await removeFromRejectedUsers(uid);
        }

        await addToDB("deletedUIDs", { [uid]: new Date().toISOString() });

        // alert(`User ${uid} has been deleted.`);
        // Remove the user from the requests state
        setRequests((prevRequests) => {
          const updatedRequests = { ...prevRequests };
          delete updatedRequests[uid];
          return updatedRequests;
        });
        setUsersData((prevUsersData) => {
          const updatedUsersData = { ...prevUsersData };
          delete updatedUsersData[uid];
          return updatedUsersData;
        });
      } catch (error) {
        console.error("Error deleting user:", error);
        alert("Error deleting user. Please try again.");
      }
    } else if (action === "cancel") {
      try {
        await cancelDeleteRequest(uid);
        alert(`Deletion request for user ${uid} has been cancelled.`);
        // Remove the user from the requests state
        setRequests((prevRequests) => {
          const updatedRequests = { ...prevRequests };
          delete updatedRequests[uid];
          return updatedRequests;
        });
      } catch (error) {
        console.error("Error cancelling deletion request:", error);
        alert("Error cancelling deletion request. Please try again.");
      }
    } else {
      //   alert("No action taken.");
    }
  };

  const filteredRequests = filterByAdmin
    ? Object.keys(requests).filter((uid) => requests[uid] === "admin")
    : Object.keys(requests);

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar isNavOpen={isNavOpen} toggleNav={toggleNav} />
      <div
        className={`flex-grow transition-all duration-300 pt-16 ${
          isNavOpen ? "ml-64" : "ml-0"
        }`}
      >
        <div className="mx-4 mt-4">
          <h1 className="text-2xl font-bold mb-4">Account Deletion Requests</h1>
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={filterByAdmin}
                onChange={() => setFilterByAdmin(!filterByAdmin)}
              />
              <span className="ml-2">Show only admin requests</span>
            </label>
          </div>
          {filteredRequests.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {filteredRequests.map((uid) => (
                <UserCard
                  key={uid}
                  user={usersData[uid] || { private_info: {}, public_info: {} }} // Provide default empty objects to avoid crashes
                  onClick={() => handleDeleteUser(uid)}
                />
              ))}
            </div>
          ) : (
            <p>No account deletion requests found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeletionRequestsPage;
