import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet-polylinedecorator";
import "leaflet/dist/leaflet.css";
import { getCollectionData } from "../firebase/firestoreFunctions"; // Adjust the import path to your API functions

const MapActiveRides = () => {
  const [hoveredRide, setHoveredRide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rides, setRides] = useState([]);
  const [showPastRides, setShowPastRides] = useState(false);

  const toggleRides = async () => {
    setLoading(true);
    setError(null);
    try {
      const activeRides = await getCollectionData("Active_Rides");
      const pastRides = await getCollectionData("Past_Rides");
      setRides(showPastRides ? pastRides : activeRides);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    toggleRides();
  }, [showPastRides]);

  useEffect(() => {
    const fetchRides = async () => {
      try {
        const activeRides = await getCollectionData("Active_Rides");
        // const pastRides = await getCollectionData("Past_Rides");
        setRides(activeRides);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRides();
  }, []);

  const isValidCoordinates = (lat, lng) => {
    return (
      typeof lat === "number" &&
      typeof lng === "number" &&
      !isNaN(lat) &&
      !isNaN(lng)
    );
  };

  const getColorForDate = (date) => {
    const now = new Date();
    const rideDate = new Date(date);
    const diffInHours = Math.abs(now - rideDate) / 36e5;

    if (diffInHours < 1) return "red";
    if (diffInHours < 6) return "orange";
    if (diffInHours < 24) return "yellow";
    return "blue";
  };
  const MapComponent = ({ rides }) => {
    const map = useMap();

    useEffect(() => {
      rides.forEach((ride) => {
        const pickupCoordinates =
          ride.pickup &&
          isValidCoordinates(ride.pickup.latitude, ride.pickup.longitude)
            ? [ride.pickup.latitude, ride.pickup.longitude]
            : null;

        const dropoffCoordinates = ride.dropoffs
          ? Object.values(ride.dropoffs)
              .map((dropoff) => {
                if (isValidCoordinates(dropoff.latitude, dropoff.longitude)) {
                  return [dropoff.latitude, dropoff.longitude];
                }
                return null;
              })
              .filter((coords) => coords !== null)
          : [];

        if (pickupCoordinates && dropoffCoordinates.length > 0) {
          const polylineCoordinates = [
            pickupCoordinates,
            ...dropoffCoordinates,
          ];

          const rideDateColor = getColorForDate(ride.date); // assuming `ride.date` contains the ride's datetime

          const polyline = L.polyline(polylineCoordinates, {
            color: rideDateColor,
          }).addTo(map);

          const arrowDecorator = L.polylineDecorator(polyline, {
            patterns: [
              {
                offset: "100%",
                repeat: 0,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 15,
                  pathOptions: { fillOpacity: 1, color: rideDateColor },
                }),
              },
            ],
          }).addTo(map);

          polyline.on("click", () => {
            window.location.href = `/dashboard/rides/${ride.id}`;
          });

          polyline.on("mouseover", () => {
            setHoveredRide(ride.id);
            polyline.setStyle({ color: "black" });
            arrowDecorator.setPatterns([
              {
                offset: "100%",
                repeat: 0,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 15,
                  pathOptions: { fillOpacity: 1, color: "black" },
                }),
              },
            ]);
          });

          polyline.on("mouseout", () => {
            setHoveredRide(null);
            polyline.setStyle({ color: rideDateColor });
            arrowDecorator.setPatterns([
              {
                offset: "100%",
                repeat: 0,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 15,
                  pathOptions: { fillOpacity: 1, color: rideDateColor },
                }),
              },
            ]);
          });
        }
      });
    }, [rides, map]);

    return null;
  };

  return (
    <div className="container">
      <h1>All Rides Map</h1>
      <button
        className="button"
        onClick={() => setShowPastRides(!showPastRides)}
      >
        {showPastRides ? "Show Active Rides" : "Show Past Rides"}
      </button>
      <div className="content">
        <div className="rides-list">
          <ul>
            {rides.map((ride) => (
              <li
                key={ride.id}
                onMouseOver={() => setHoveredRide(ride.id)}
                onMouseOut={() => setHoveredRide(null)}
                onClick={() =>
                  (window.location.href = `/dashboard/rides/${ride.id}`)
                }
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    hoveredRide === ride.id ? "#f0f0f0" : "white",
                }}
              >
                Ride ID: {ride.id}, Creator: {ride.creator?.username}
              </li>
            ))}
          </ul>
        </div>
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          style={{ height: "600px", width: "70%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapComponent rides={rides} />
        </MapContainer>
      </div>
      <style>{`
      .container {
        padding: 20px;
      }
      .content {
        display: flex;
        gap: 20px;
      }
      .rides-list {
        width: 30%;
      }
      .rides-list ul {
        list-style-type: none;
        padding: 0;
      }
      .rides-list li {
        padding: 10px;
        border: 1px solid #ccc;
        margin-bottom: 5px;
      }
    `}</style>
    </div>
  );
};

export default MapActiveRides;
