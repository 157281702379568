import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { getUsers } from "../firebase/dbFunctions";

const InviteCodesPage = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      console.log("Fetching");
      try {
        const usersData = await getUsers();
        const filteredUsers = usersData.filter(
          (user) => user.private_info?.uid
        );
        filteredUsers.sort((a, b) => {
          if (a.private_info.dateJoined < b.private_info.dateJoined) return -1;
          if (a.private_info.dateJoined > b.private_info.dateJoined) return 1;
          if (a.private_info.invite_code < b.private_info.invite_code)
            return -1;
          if (a.private_info.invite_code > b.private_info.invite_code) return 1;
          return 0;
        });
        console.log("Filtered and Sorted Users:", filteredUsers);
        setUsers(filteredUsers);
        localStorage.setItem("usersData", JSON.stringify(filteredUsers));
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const storedUsers = localStorage.getItem("usersData");
    if (storedUsers) {
      setUsers(JSON.parse(storedUsers));
    } else {
      fetchUsers();
    }
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar />
      <div className="flex-grow mx-4 overflow-y-auto">
        <h1 className="text-2xl font-bold my-4">Users Invite Codes</h1>
        <div className="table-container">
          <table className="border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  UID
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Name
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Invite Code
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.private_info?.uid}>
                  <td className="border border-gray-400 px-4 py-2">
                    {user.private_info?.uid}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {user.private_info?.first_name}{" "}
                    {user.private_info?.last_name}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {user.private_info?.invite_code || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InviteCodesPage;
