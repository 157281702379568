import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Header from "../components/Header";

const Layout = ({ children, tabName, userName }) => {
  // console.log(tabName, userName);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => setIsNavOpen(!isNavOpen);

  return (
    <div className="flex h-screen bg-gray-100">
      <Header
        toggleNav={toggleNav}
        isNavOpen={isNavOpen}
        tabName={tabName}
        userName={userName}
      />
      <NavBar isNavOpen={isNavOpen} toggleNav={toggleNav} />
      <div
        className={`flex-grow transition-all duration-300 pt-16 ${
          isNavOpen ? "ml-64" : "ml-0"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
