import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { getGroups } from "../firebase/dbFunctions"; // Update function import to fetch groups

const GroupLists = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      console.log("Fetching groups");
      try {
        const cachedData = localStorage.getItem("groups");
        const cachedTimestamp = localStorage.getItem("groupsTimestamp");
        const currentTime = new Date().getTime();

        if (
          cachedData &&
          cachedTimestamp &&
          currentTime - cachedTimestamp < 1800000
        ) {
          const groupsData = JSON.parse(cachedData);
          console.log("Using cached groups data");
          const sortedGroups = groupsData.sort(
            (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
          );
          setGroups(sortedGroups);
        } else {
          const groupsData = await getGroups();
          console.log("Fetching new groups data");
          localStorage.setItem("groups", JSON.stringify(groupsData));
          localStorage.setItem("groupsTimestamp", currentTime.toString());
          const sortedGroups = groupsData.sort(
            (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
          );
          setGroups(sortedGroups);
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []); // Empty dependency array to ensure useEffect runs only once when component mounts
  // const handleGroupClick = (groupId) => {
  //   // Navigate to the group's details page
  //   navigate(`/details/groups/${groupId}`);
  // };

  const handleGroupClick = (group) => {
    // Navigate to the group's details page with group data
    navigate(`/details/groups/${group.id}`, { state: { group } });
  };

  const handleDeleteGroup = async (groupId) => {
    const confirmDelete = window.confirm("UNFINISHED");
    // if (confirmDelete) {
    //   try {
    //     // await deleteGroup(groupId); // Call deleteGroup function from dbFunctions
    //     const updatedGroups = groups.filter((group) => group.id !== groupId);
    //     setGroups(updatedGroups); // Update component state after group deletion
    //   } catch (error) {
    //     console.error("Error deleting group:", error);
    //   }
    // }
  };

  const renderValue = (value) => {
    return value ? value : "Missing"; // Display "Missing" if the value is missing
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar />
      <div className="flex-grow mx-4 overflow-y-auto">
        <h1 className="text-2xl font-bold my-4">Groups</h1>
        <div className="table-container">
          <table className="border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Group ID
                </th>
                {/* <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Group Name
                </th> */}
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Leader
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Date
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Members
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => (
                <tr
                  key={group.id}
                  className="cursor-pointer text-blue-600 hover:underline"
                  onClick={() => handleGroupClick(group)}
                >
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(group.id)}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(group.creatorUID || "N/A")}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(group.dateCreated || "N/A")}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {group.memberStatus && group.memberStatus.length > 0
                      ? renderValue(group.memberStatus.join(", "))
                      : "N/A"}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    <button onClick={() => handleDeleteGroup(group.id)}>
                      Delete Group
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GroupLists;
