import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserCard from "../components/UserCard";
import NavBar from "../components/NavBar";
import { getUsers, getUsersPublicData } from "../firebase/userFunctions";

const UsersContent = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [genderFilter, setGenderFilter] = useState("");
  const [idStatusFilter, setIdStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [userCount, setUserCount] = useState(0);

  const fetchUsers = async () => {
    console.log("Fetching");
    try {
      // const usersData = await getUsers();
      const usersData = await getUsersPublicData();
      setUsers(usersData);
      setFilteredUsers(usersData); // Initialize filtered users
      localStorage.setItem("usersData", JSON.stringify(usersData)); // Store data in localStorage
      setUserCount(usersData.length); // Set the user count
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    filterUsers(); // eslint-disable-next-line
  }, [genderFilter, idStatusFilter, sortOrder]);

  const filterUsers = () => {
    let filtered = users;

    if (genderFilter) {
      filtered = filtered.filter((user) => {
        const gender = user.private_info?.gender;
        return (
          gender === genderFilter ||
          (genderFilter === "other" && gender !== "man" && gender !== "woman")
        );
      });
    }

    if (idStatusFilter) {
      filtered = filtered.filter((user) => {
        const idStatus = user.private_info?.id_status;
        if (typeof idStatus === "string") {
          return idStatus === idStatusFilter;
        } else if (typeof idStatus === "object" && idStatus !== null) {
          return idStatusFilter in idStatus;
        }
        return false;
      });
    }

    if (sortOrder) {
      filtered = filtered.slice().sort((a, b) => {
        const dateA = a.private_info?.dateJoined
          ? new Date(a.private_info.dateJoined)
          : null;
        const dateB = b.private_info?.dateJoined
          ? new Date(b.private_info.dateJoined)
          : null;

        if (!dateA) return 1; // Move users with no dateJoined to the end
        if (!dateB) return -1;

        return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
      });
    }

    setFilteredUsers(filtered);
  };

  const handleUserClick = (userId) => {
    navigate(`/dashboard/users/${userId}`);
  };

  const toggleNav = () => setIsNavOpen(!isNavOpen);

  const refreshData = () => {
    const storedData = localStorage.getItem("usersData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsers(parsedData);
      setFilteredUsers(parsedData);
      setUserCount(parsedData.length);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar isNavOpen={isNavOpen} toggleNav={toggleNav} />
      <div
        className={`flex-grow transition-all duration-300 pt-5${
          isNavOpen ? "ml-64" : "ml-0"
        }`}
      >
        <div className="mx-4 mt-4">
          <div className="flex space-x-4 mb-4">
            <div>
              <label
                htmlFor="genderFilter"
                className="block text-sm font-medium text-gray-700"
              >
                Gender
              </label>
              <select
                id="genderFilter"
                name="genderFilter"
                value={genderFilter}
                onChange={(e) => setGenderFilter(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="">All</option>
                <option value="man">man</option>
                <option value="woman">woman</option>
                <option value="other">other</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="idStatusFilter"
                className="block text-sm font-medium text-gray-700"
              >
                ID Status
              </label>
              <select
                id="idStatusFilter"
                name="idStatusFilter"
                value={idStatusFilter}
                onChange={(e) => setIdStatusFilter(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="">All</option>
                <option value="Verified">Verified</option>
                <option value="Submitted">Submitted</option>
                <option value="Flagged">Flagged</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="sortOrder"
                className="block text-sm font-medium text-gray-700"
              >
                Sort by Date Joined
              </label>
              <select
                id="sortOrder"
                name="sortOrder"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="">None</option>
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
            <div className="flex items-center">
              <button
                onClick={refreshData}
                className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                Refresh Data
              </button>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-lg font-semibold">Total Users: {userCount}</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-y-auto">
            {filteredUsers.map((user) => {
              const userId = user.uid;
              if (!userId) {
                return null; // Skip rendering if userId is not available
              }

              return (
                <UserCard
                  key={userId}
                  user={user}
                  onClick={() => handleUserClick(userId)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersContent;
