import React from "react";

const Header = ({ toggleNav, isNavOpen, tabName, userName }) => {
  return (
    <header className="fixed top-0 left-0 w-full bg-gray-800 text-white flex justify-between items-center p-4 z-40">
      <button
        className="p-2 bg-gray-700 text-white rounded"
        onClick={toggleNav}
      >
        {isNavOpen ? "Hide Menu" : "Open Menu"}
      </button>
      <h1 className="text-center flex-grow">{tabName}</h1>
      <span className="p-2">{userName}</span>
    </header>
  );
};

export default Header;
