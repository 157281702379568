import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { getKeyValuesfromDB } from "../firebase/dbFunctions"; // Import the function to fetch rejected users

const RejectedPage = () => {
  const navigate = useNavigate();
  const [rejectedUsers, setRejectedUsers] = useState([]);

  useEffect(() => {
    const fetchRejectedUsers = async () => {
      try {
        const rejectedUsersData = await getKeyValuesfromDB("rejectedUsers"); // Call function to fetch rejected users
        console.log("rejectedUsersData:", rejectedUsersData);
        setRejectedUsers(rejectedUsersData); // Update component state with fetched rejected users
      } catch (error) {
        console.error("Error fetching rejected users:", error);
      }
    };

    fetchRejectedUsers();
  }, []);

  const handleUserClick = (userId) => {
    navigate(`/dashboard/users/${userId}`);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar />
      <div className="flex-grow mx-4 overflow-y-auto">
        <h1 className="text-2xl font-bold my-4">Rejected Users</h1>
        <div className="table-container">
          <table className="border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  UID
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Reason
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(rejectedUsers).map((uid) => (
                <tr
                  key={uid}
                  className="cursor-pointer text-blue-600 hover:underline"
                  onClick={() => handleUserClick(uid)}
                >
                  <td className="border border-gray-400 px-4 py-2">{uid}</td>
                  <td className="border border-gray-400 px-4 py-2">
                    {rejectedUsers[uid]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RejectedPage;
