import { getFunctions, httpsCallable } from "firebase/functions";
import { listAll, deleteObject } from "firebase/storage";
import { realTimeDB, storage } from "./config";
import { sendNotification } from "./notificationFunctions";

export const getUsers = async () => {
  try {
    const usersRef = realTimeDB.ref("/users"); // Reference to the 'users' node in the database
    const snapshot = await usersRef.once("value"); // Fetch data once from the 'users' node
    const usersData = snapshot.val(); // Extract the value from the snapshot

    // Convert the object of usersData to an array of users
    const usersArray = Object.keys(usersData).map((userId) => ({
      uid: userId,
      ...usersData[userId],
    }));

    return usersArray;
  } catch (error) {
    console.error("Error fetching users:", error);
    return []; // Return an empty array in case of error
  }
};

export const getNewUsers = async () => {
  try {
    const newUsersRef = realTimeDB.ref("/newUsersUnverified"); // Reference to the 'newUsersUnverified' node in the database
    const snapshot = await newUsersRef.once("value"); // Fetch data once from the 'newUsersUnverified' node
    const newUsersData = snapshot.val(); // Extract the value from the snapshot

    // Convert the object of newUsersData to an array of new users
    const newUsersArray = Object.keys(newUsersData);

    return newUsersArray;
  } catch (error) {
    console.error("Error fetching new users:", error);
    return []; // Return an empty array in case of error
  }
};

export const getKeyValuesfromDB = async (node) => {
  try {
    const newUsersRef = realTimeDB.ref(`/${node}`); // Reference to the 'newUsersUnverified' node in the database
    const snapshot = await newUsersRef.once("value"); // Fetch data once from the 'newUsersUnverified' node
    const newUsersData = snapshot.val(); // Extract the value from the snapshot
    return newUsersData;
  } catch (error) {
    console.error("Error fetching values and keys", error);
    return []; // Return an empty array in case of error
  }
};

export const getDataFromUid = async (uid) => {
  try {
    const userRef = realTimeDB.ref(`/users/${uid}`); // Reference to the user's node in the database
    const snapshot = await userRef.once("value"); // Fetch data once from the user's node
    const userData = snapshot.val(); // Extract the value from the snapshot

    return userData; // Return the user data
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Throw the error to handle it in the component
  }
};

export const verifyUser = async (uid, status, reason = null) => {
  console.log("verifyUser", uid, status, reason);
  try {
    // Reference to the verification status of the user
    const userRef = realTimeDB.ref(`/users/${uid}/private_info/id_status`);
    const newUsersRef = realTimeDB.ref("/newUsersUnverified");
    const rejectedUsersRef = realTimeDB.ref("/rejectedUsers");

    if (status !== "Verified") {
      await userRef.set({ [status]: reason });

      const notificationPayload = {
        userId: uid,
        title: "Verification Status",
        body: `Your ID verification was rejected. Reason: ${reason}`,
        highPriority: true,
        data: { action: "OPEN_ID_REUPLOAD_PAGE", status, reason },
      };

      await sendNotification(notificationPayload);
      await rejectedUsersRef.child(uid).set({ [status]: reason });
    } else {
      await userRef.set(status);

      const notificationPayload = {
        userId: uid,
        title: "Verification Status",
        body: "Your ID verification was successful.",
        highPriority: true,
        data: { status },
      };

      await sendNotification(notificationPayload);
    }

    await newUsersRef.child(uid).remove();
    return true;
  } catch (error) {
    console.error("Error verifying user:", error);
    return false;
  }
};

export const requestDeleteUser = async (uid) => {
  try {
    const deletionRequestRef = realTimeDB.ref(
      `/accountsDeletionRequests/${uid}`
    );
    await deletionRequestRef.set({
      requestedBy: "admin",
    });
    console.log(`Deletion request for user ${uid} has been added.`);
  } catch (error) {
    console.error("Error requesting user deletion:", error);
  }
};

export const cancelDeleteRequest = async (uid) => {
  try {
    const deletionRequestRef = realTimeDB.ref(
      `/accountsDeletionRequests/${uid}`
    );
    await deletionRequestRef.remove();
    // console.log(`Deletion request for user ${uid} has been cancelled.`);
  } catch (error) {
    console.error("Error cancelling deletion request:", error);
  }
};

export const removeFromRejectedUsers = async (uid) => {
  try {
    const rejectedUsersRef = realTimeDB.ref(`/rejectedUsers/${uid}`);
    await rejectedUsersRef.remove();
    // console.log(`Deletion request for user ${uid} has been cancelled.`);
  } catch (error) {
    console.error("Error cancelling deletion request:", error);
  }
};

export const getDeletionRequests = async () => {
  try {
    const requestsRef = realTimeDB.ref("/accountsDeletionRequests"); // Reference to the 'accountsDeletionRequests' node in the database
    const snapshot = await requestsRef.once("value"); // Fetch data once from the 'accountsDeletionRequests' node
    const requestsData = snapshot.val(); // Extract the value from the snapshot

    if (requestsData) {
      const requestsObject = Object.keys(requestsData).reduce((acc, uid) => {
        acc[uid] = requestsData[uid].requestedBy;
        return acc;
      }, {});
      return requestsObject;
    } else {
      return {}; // Return an empty object if there are no deletion requests
    }
  } catch (error) {
    console.error("Error fetching deletion requests:", error);
    return {}; // Return an empty object in case of error
  }
};

const deleteAllFilesInFolder = async (folderRef) => {
  const listResult = await listAll(folderRef);

  // Delete all files
  const deletePromises = listResult.items.map((fileRef) =>
    deleteObject(fileRef)
  );

  // Recursively delete all subfolders
  const subfolderDeletePromises = listResult.prefixes.map((subfolderRef) =>
    deleteAllFilesInFolder(subfolderRef)
  );

  await Promise.all([...deletePromises, ...subfolderDeletePromises]);
};

export const deleteUserFromStorage = async (uid) => {
  try {
    const userFolderRef = storage.ref().child(`users/${uid}`);
    // const userFolderRef = ref(storage, `users/${uid}`);
    await deleteAllFilesInFolder(userFolderRef);
    console.log(
      `Successfully deleted all files and folders for user with uid: ${uid}`
    );
  } catch (error) {
    console.error("Error deleting user from storage:", error);
  }
};

export const deleteUserFromAuth = async (uid) => {
  const functions = getFunctions(undefined, "europe-west2");
  const deleteUser = httpsCallable(functions, "deleteUserFromAuth");

  try {
    const result = await deleteUser({ uid });
    console.log(result.data.message);
  } catch (error) {
    console.error("Error deleting user from auth:", error);
  }
};

export const deleteUserFromDB = async (uid) => {
  try {
    const userRef = realTimeDB.ref(`/users/${uid}`);
    await userRef.remove();
  } catch (error) {
    console.error("Error deleting user from database:", error);
  }
};

export const addToDB = async (node, data) => {
  try {
    const ref = realTimeDB.ref(`/${node}`);
    await ref.update(data); // Use update instead of set to merge data
    return true;
  } catch (error) {
    console.error("Error adding data to database:", error);
    return false;
  }
};

export const getGroups = async () => {
  try {
    const groupsRef = realTimeDB.ref("/rides_groups"); // Reference to the 'groups' node in the database
    const snapshot = await groupsRef.once("value"); // Fetch data once from the 'groups' node
    const groupsData = snapshot.val(); // Extract the value from the snapshot

    if (!groupsData) {
      // Return an empty array if groupsData is null or undefined
      return [];
    }

    console.log("groupsData", groupsData);

    // Convert the object of groupsData to an array of groups
    const groupsArray = Object.keys(groupsData).map((groupId) => ({
      id: groupId,
      ...groupsData[groupId],
      // members: Array.isArray(groupsData[groupId].members)
      //   ? groupsData[groupId].members
      //   : [], // Ensure members is an array
      members: groupsData[groupId].members
        ? Object.values(groupsData[groupId].members)
        : [], // Ensure members is an array
    }));

    console.log("groupsArray", groupsArray);

    return groupsArray;
  } catch (error) {
    console.error("Error fetching groups:", error);
    return []; // Return an empty array in case of error
  }
};

export const getGroupById = async (groupId) => {
  try {
    const groupRef = realTimeDB.ref(`/rides_groups/${groupId}`); // Reference to the specific group node in the database
    const snapshot = await groupRef.once("value"); // Fetch data once from the group node
    const groupData = snapshot.val(); // Extract the value from the snapshot

    if (!groupData) {
      throw new Error("Group not found");
    }

    return {
      id: groupId,
      ...groupData,
    };
  } catch (error) {
    console.error("Error fetching group details:", error);
    throw error; // Throw the error to handle it in the component
  }
};
