import React, { useEffect, useState } from "react";
import { getCollectionData } from "../firebase/firestoreFunctions";

const RidesPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rides, setRides] = useState([]);

  useEffect(() => {
    const fetchRides = async () => {
      try {
        const ridesData = await getCollectionData("Active_Rides");
        setRides(ridesData); // Store the fetched data in state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRides();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Active Rides</h1>
      {rides.length === 0 ? (
        <p>No active rides available.</p>
      ) : (
        <ul>
          {rides.map((ride) => (
            <li key={ride.id}>
              <h2>{ride.name}</h2>
              {ride.pickup && (
                <>
                  <p>
                    <strong>Pickup Address:</strong>{" "}
                    {ride.pickup.addressLong || "N/A"}
                  </p>
                  <p>
                    <strong>Pickup Postcode:</strong>{" "}
                    {ride.pickup.postcode || "N/A"}
                  </p>
                  <p>
                    <strong>Pickup Coordinates:</strong>{" "}
                    {ride.pickup.latitude || "N/A"},{" "}
                    {ride.pickup.longitude || "N/A"}
                  </p>
                </>
              )}
              {ride.dropoffs &&
                Object.values(ride.dropoffs).map((dropoff, index) => (
                  <div key={index}>
                    <p>
                      <strong>Dropoff Address:</strong>{" "}
                      {dropoff.addressLong || "N/A"}
                    </p>
                    <p>
                      <strong>Dropoff Postcode:</strong>{" "}
                      {dropoff.postcode || "N/A"}
                    </p>
                    <p>
                      <strong>Dropoff Coordinates:</strong>{" "}
                      {dropoff.latitude || "N/A"}, {dropoff.longitude || "N/A"}
                    </p>
                  </div>
                ))}
              <p>
                <strong>Ride Creator:</strong> {ride.creator?.username || "N/A"}
              </p>
              <p>
                <strong>Ride Status:</strong> {ride.status || "N/A"}
              </p>
              <p>
                <strong>Ride DateTime:</strong>{" "}
                {ride.dateTime
                  ? new Date(ride.dateTime).toLocaleString()
                  : "N/A"}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RidesPage;
