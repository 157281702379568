import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { getUsers } from "../firebase/dbFunctions";

const UserPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch users from Firebase Realtime Database when component mounts
    const fetchUsers = async () => {
      console.log("Fetching");
      try {
        const usersData = await getUsers(); // Call getUsers function from dbFunctions
        console.log(JSON.stringify(usersData[0]));
        setUsers(usersData); // Update component state with fetched users
        // console.log("firstUser :", usersData[0]);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers(); // Call fetchUsers function
  }, []); // Empty dependency array to ensure useEffect runs only once when component mounts

  const handleUserClick = (userId) => {
    // Navigate to the user's dashboard page
    navigate(`/dashboard/users/${userId}`);
  };

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      try {
        // await deleteUser(userId); // Call deleteUser function from dbFunctions
        const updatedUsers = users.filter(
          (user) => user.private_info.uid !== userId
        );
        setUsers(updatedUsers); // Update component state after user deletion
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const renderValue = (value) => {
    return value ? value : "Missing"; // Display "Missing" if the value is missing
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar />
      <div className="flex-grow mx-4 overflow-y-auto">
        <h1 className="text-2xl font-bold my-4">Users</h1>
        <div className="table-container">
          <table className="border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  UID
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Name
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  ID Status
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Email
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Gender
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  University
                </th>
                <th className="sticky top-0 bg-gray-800 text-white border border-gray-400 px-4 py-2 z-10">
                  Actions
                </th>{" "}
                {/* Add column header for actions */}
                {/* Add other relevant headers for additional columns */}
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr
                  key={user.uid}
                  className="cursor-pointer text-blue-600 hover:underline"
                  onClick={() => handleUserClick(user.private_info?.uid)}
                >
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(user.uid)}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(
                      user.private_info?.first_name +
                        " " +
                        user.private_info?.last_name
                    )}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(JSON.stringify(user.private_info?.id_status))}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(user.private_info?.email)}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(user.private_info?.gender)}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    {renderValue(user.public_info?.university)}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    <button
                      onClick={() => handleDeleteUser(user.private_info?.uid)}
                    >
                      Delete User
                    </button>
                  </td>
                  {/* Add button for deleting user */}
                  {/* Add additional columns for other relevant information */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
