import React, { useEffect, useState } from "react";
import {
  deleteRideById,
  getCollectionData,
  switchRideCollection,
} from "../firebase/firestoreFunctions";
import { Link } from "react-router-dom";

const RidesListPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rides, setRides] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "dateTime",
    direction: "asc",
  });
  const [filterUser, setFilterUser] = useState("");
  const [searchMode, setSearchMode] = useState(
    localStorage.getItem("searchMode") || "past"
  ); // "active" or "past"

  useEffect(() => {
    const fetchRides = async () => {
      try {
        let ridesData;
        if (searchMode === "active") {
          ridesData = await getCollectionData("Active_Rides");
        } else {
          ridesData = await getCollectionData("Past_Rides");
        }
        setRides(ridesData); // Store the fetched data in state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRides();
  }, [searchMode]); // Fetch rides when search mode changes

  const handleDeleteRide = async (rideId) => {
    const confirmDelete = window.prompt(
      "Are you sure you want to delete this ride? (delete)"
    );
    if (confirmDelete === "delete") {
      try {
        await deleteRideById(
          searchMode === "active" ? "Active_Rides" : "Past_Rides",
          rideId
        );
        setRides(rides.filter((ride) => ride.id !== rideId)); // Remove the deleted ride from state
        console.log(`Ride with ID ${rideId} successfully deleted`);
      } catch (error) {
        console.error(`Error deleting ride with ID ${rideId}:`, error);
      }
    }
  };

  const handleSwitchRideCollection = async (rideId) => {
    try {
      // Call the switch collection function
      await switchRideCollection(
        searchMode === "active" ? "Active_Rides" : "Past_Rides",
        rideId
      );
      // Re-fetch rides data to update UI
      const updatedRides = await getCollectionData(
        searchMode === "active" ? "Active_Rides" : "Past_Rides"
      );
      setRides(updatedRides);
    } catch (error) {
      console.error(
        `Error switching collection for ride with ID ${rideId}:`,
        error
      );
    }
  };

  const sortRides = (rides, config) => {
    const sortedRides = [...rides];
    sortedRides.sort((a, b) => {
      if (config.key === "pickup.addressLong" || config.key === "dropoffs") {
        return config.direction === "asc"
          ? (a.pickup?.addressLong || "").localeCompare(
              b.pickup?.addressLong || ""
            )
          : (b.pickup?.addressLong || "").localeCompare(
              a.pickup?.addressLong || ""
            );
      } else if (config.key === "dateTime") {
        return config.direction === "asc"
          ? new Date(a.dateTime) - new Date(b.dateTime)
          : new Date(b.dateTime) - new Date(a.dateTime);
      } else {
        return config.direction === "asc"
          ? (a[config.key] || "").localeCompare(b[config.key] || "")
          : (b[config.key] || "").localeCompare(a[config.key] || "");
      }
    });
    return sortedRides;
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const filteredRides = filterUser
    ? rides.filter((ride) => ride.creator?.username.includes(filterUser))
    : rides;

  const sortedRides = sortRides(filteredRides, sortConfig);

  const toggleSearchMode = () => {
    const newSearchMode = searchMode === "active" ? "past" : "active";
    setSearchMode(newSearchMode);
    localStorage.setItem("searchMode", newSearchMode);
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="page-padding">
      <h1>{searchMode === "active" ? "Active Rides" : "Past Rides"}</h1>
      <button onClick={toggleSearchMode}>
        {searchMode === "active"
          ? "Switch to Past Rides"
          : "Switch to Active Rides"}
      </button>
      <input
        type="text"
        placeholder="Filter by creator username"
        value={filterUser}
        onChange={(e) => setFilterUser(e.target.value)}
        className="filter-input"
      />
      {rides.length === 0 ? (
        <p className="no-data">
          No {searchMode === "active" ? "active" : "past"} rides available.
        </p>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th
                onClick={() => handleSort("id")}
                className={
                  sortConfig.key === "id" ? `sort-${sortConfig.direction}` : ""
                }
              >
                Ride ID
              </th>
              <th
                onClick={() => handleSort("dateTime")}
                className={
                  sortConfig.key === "dateTime"
                    ? `sort-${sortConfig.direction}`
                    : ""
                }
              >
                Date and Time
              </th>
              <th
                onClick={() => handleSort("pickup.addressLong")}
                className={
                  sortConfig.key === "pickup.addressLong"
                    ? `sort-${sortConfig.direction}`
                    : ""
                }
              >
                Pickup Address
              </th>
              <th
                onClick={() => handleSort("dropoffs")}
                className={
                  sortConfig.key === "dropoffs"
                    ? `sort-${sortConfig.direction}`
                    : ""
                }
              >
                Dropoff Address
              </th>
              <th
                onClick={() => handleSort("creator.username")}
                className={
                  sortConfig.key === "creator.username"
                    ? `sort-${sortConfig.direction}`
                    : ""
                }
              >
                Creator Username
              </th>
              <th
                onClick={() => handleSort("uid")}
                className={
                  sortConfig.key === "uid" ? `sort-${sortConfig.direction}` : ""
                }
              >
                UID
              </th>
              <th
                onClick={() => handleSort("status")}
                className={
                  sortConfig.key === "status"
                    ? `sort-${sortConfig.direction}`
                    : ""
                }
              >
                Status
              </th>
              <th>Switch</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {sortedRides.map((ride) => (
              <tr key={ride.id}>
                <td>
                  <Link
                    to={{
                      pathname: `/dashboard/rides/${ride.id}`,
                      // state: {
                      //   collection:
                      //     searchMode === "active"
                      //       ? "Active_Rides"
                      //       : "Past_Rides",
                      // },
                    }}
                  >
                    {ride.id}
                  </Link>
                </td>
                <td>{new Date(ride.dateTime).toLocaleString()}</td>
                <td>{ride.pickup?.addressLong || "N/A"}</td>
                <td>
                  {Array.isArray(ride.dropoffs) && ride.dropoffs.length > 0
                    ? ride.dropoffs.map((dropoff, index) => (
                        <div key={index}>{dropoff.addressLong || "N/A"}</div>
                      ))
                    : "N/A"}
                </td>
                <td>{ride.creator?.username || "N/A"}</td>
                <td>{ride.creator?.uid || "N/A"}</td>
                <td>{ride.status || "N/A"}</td>
                <td>
                  <button
                    className="switch-button"
                    onClick={() => handleSwitchRideCollection(ride.id)}
                  >
                    Switch
                  </button>
                </td>
                <td>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteRide(ride.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <style>{`
      .delete-button {
        background-color: #dc3545;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
      }

      .switch-button {
        background-color: lightblue;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
      }

      
      .delete-button:hover {
        background-color: #c82333;
      }
      
        .page-padding {
          padding: 20px; /* Adjust the padding as needed */
        }
        
        .table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        .table th,
        .table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        .table th {
          cursor: pointer;
          background-color: #f2f2f2;
        }

        .table th.sort-asc::after {
          content: " \\2191"; /* Up arrow */
        }

        .table th.sort-desc::after {
          content: " \\2193"; /* Down arrow */
        }

        .filter-input {
          margin-bottom: 20px;
          padding: 10px;
          width: 100%;
          box-sizing: border-box;
        }

        .loading,
        .error,
        .no-data {
          text-align: center;
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default RidesListPage;
