import { getFunctions, httpsCallable } from "firebase/functions";
// import { realTimeDB } from "../firebase/config";

export const sendNotification = async (notificationPayload) => {
  const functions = getFunctions(undefined, "europe-west2");
  const sendNotificationOnCall = httpsCallable(
    functions,
    "sendNotificationOnCall"
  );
  try {
    await sendNotificationOnCall(notificationPayload);
    console.log("Notification sent successfully");
  } catch (error) {
    console.error("Error sending notification:", error);
  }
};
