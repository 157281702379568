import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

// Fetch all documents from the specified collection in Firestore or a specific document if id is provided
export const getCollectionData = async (collectionName, id = null) => {
  try {
    const firestore = getFirestore();
    const colRef = collection(firestore, collectionName);

    if (id) {
      const docRef = doc(colRef, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return {
          id: docSnap.id,
          ...docSnap.data(),
        };
      } else {
        console.error(
          `No document found with id ${id} in collection ${collectionName}`
        );
        return null; // Return null if document not found
      }
    } else {
      const snapshot = await getDocs(colRef);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return data;
    }
  } catch (error) {
    console.error(
      `Error fetching data from collection ${collectionName}:`,
      error
    );
    return id ? null : []; // Return null if specific document fetch fails, otherwise return empty array
  }
};

// Fetch a specific document from a collection by its ID in Firestore
export const getRideDataById = async (collectionName, rideId) => {
  try {
    const firestore = getFirestore();
    const docRef = doc(firestore, collectionName, rideId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      console.error(
        `No document found with ID ${rideId} in collection ${collectionName}`
      );
      return null;
    }
  } catch (error) {
    console.error(
      `Error fetching document with ID ${rideId} from collection ${collectionName}:`,
      error
    );
    return null; // Return null in case of error
  }
};

// Delete a specific document from a collection by its ID in Firestore
export const deleteRideById = async (collectionName, rideId) => {
  try {
    const firestore = getFirestore();
    const docRef = doc(firestore, collectionName, rideId);
    await deleteDoc(docRef);
    console.log(
      `Document with ID ${rideId} successfully deleted from collection ${collectionName}`
    );
  } catch (error) {
    console.error(
      `Error deleting document with ID ${rideId} from collection ${collectionName}:`,
      error
    );
  }
};

// Function to switch a ride between Active_Rides and Past_Rides collections
export const switchRideCollection = async (currentCollection, rideId) => {
  try {
    // const currentCollection = (await getRideDataById("Active_Rides", rideId))
    //   ? "Active_Rides"
    //   : "Past_Rides";
    const targetCollection =
      currentCollection === "Active_Rides" ? "Past_Rides" : "Active_Rides";

    const rideData = await getRideDataById(currentCollection, rideId);
    if (!rideData) {
      console.error(
        `Failed to find the ride with ID ${rideId} in ${currentCollection}`
      );
      return; // Exit if no ride data found
    }

    // Add the ride to the target collection
    await setDoc(doc(getFirestore(), targetCollection, rideId), rideData);
    console.log(`Ride with ID ${rideId} moved to ${targetCollection}`);

    // Delete the ride from the current collection
    await deleteRideById(currentCollection, rideId);
    console.log(`Ride with ID ${rideId} removed from ${currentCollection}`);
  } catch (error) {
    console.error("Error switching ride collection:", error);
  }
};
