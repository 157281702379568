import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";

const AdminPage = () => {
  const [uid, setUid] = useState("");
  const [claims, setClaims] = useState("");
  const [message, setMessage] = useState("");

  const handleSetClaims = async () => {
    if (!uid || !claims) {
      setMessage("UID and claims are required.");
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        setMessage("No authenticated user found.");
        return;
      }

      const idToken = await user.getIdToken();

      // Initialize Firebase Functions with the correct region
      const functions = getFunctions(undefined, "europe-west2");
      const setCustomClaims = httpsCallable(functions, "setUserClaim");

      const claimsObject = JSON.parse(claims); // Parse the claims input as JSON
      const result = await setCustomClaims(
        { uid, claims: claimsObject },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setMessage(result.data.message);
    } catch (error) {
      console.error("Error setting custom claims:", error);
      setMessage(
        "Error setting custom claims. Please check the console for details."
      );
    }
  };

  return (
    <div className="admin-page min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">Admin Page</h1>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            UID:
            <input
              type="text"
              value={uid}
              onChange={(e) => setUid(e.target.value)}
              placeholder="Enter user UID"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Claims (JSON format):
            <input
              type="text"
              value={claims}
              onChange={(e) => setClaims(e.target.value)}
              placeholder='Enter claims as JSON, e.g., {"admin": true}'
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            />
          </label>
        </div>
        <button
          onClick={handleSetClaims}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
        >
          Set Claims
        </button>
        {message && <p className="mt-4 text-center text-gray-700">{message}</p>}
      </div>
    </div>
  );
};

export default AdminPage;
