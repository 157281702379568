import React from "react";

const UserCard = ({ user, onClick }) => {
  const username = user?.username || "Unknown User";
  const profilePicture =
    user?.profile_picture_url || "https://via.placeholder.com/150";
  const uid = user?.uid || "Unknown UID";
  const phoneNumber = user?.phoneNumber || "Unknown Phone Number";
  const dateCreated = user?.dateCreated
    ? new Date(user.dateCreated).toLocaleDateString()
    : "Unknown Date";

  return (
    <div
      className="bg-white shadow-md rounded p-4 m-2 cursor-pointer flex flex-col items-center"
      onClick={onClick}
    >
      <img
        className="w-24 h-24 rounded-full mb-4 object-cover"
        src={profilePicture}
        alt={`${username}`}
      />
      <h2 className="text-lg font-bold">{username}</h2>
      <p className="text-gray-600">UID: {uid}</p>
      <p className="text-gray-600">Phone: {phoneNumber}</p>
      <p className="text-gray-400 text-xs">Joined: {dateCreated}</p>
    </div>
  );
};

export default UserCard;
