import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { getGroupById } from "../firebase/dbFunctions";

const GroupDetails = () => {
  const { groupId } = useParams(); // Get the groupId from the URL parameters
  const location = useLocation();
  const [group, setGroup] = useState(location.state?.group || null);
  const [loading, setLoading] = useState(!group);

  useEffect(() => {
    if (!group) {
      // Fetch group data from Firebase if not available in location.state
      const fetchGroup = async () => {
        setLoading(true);
        try {
          const groupData = await getGroupById(groupId); // Define getGroupById in dbFunctions
          setGroup(groupData);
        } catch (error) {
          console.error("Error fetching group:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchGroup();
    }
  }, [groupId, group]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  if (!group) {
    return (
      <div className="flex justify-center items-center h-screen">
        Group not found
      </div>
    );
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "accepted":
        return "bg-green-100";
      case "rejected":
        return "bg-red-100";
      case "awaiting":
        return "bg-yellow-100";
      default:
        return "bg-gray-100";
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-3xl font-bold mb-4">
        {group.name || "Group Details"}
      </h2>
      <p className="mb-2">
        <span className="font-semibold">Status:</span> {group.status}
      </p>
      <p className="mb-2">
        <span className="font-semibold">Created At:</span>{" "}
        {new Date(group.dateCreated).toLocaleString()}
      </p>
      <p className="mb-2">
        <span className="font-semibold">Accepted At:</span>{" "}
        {new Date(group.acceptedAt).toLocaleString()}
      </p>
      <p className="mb-2">
        <span className="font-semibold">Creator Ride ID:</span>{" "}
        {group.creatorRideId}
      </p>
      <p className="mb-2">
        <span className="font-semibold">Creator UID:</span> {group.creatorUID}
      </p>
      <p className="mb-2">
        <span className="font-semibold">Group ID:</span> {group.groupId}
      </p>

      <h3 className="text-2xl font-semibold mt-6 mb-4">Members</h3>
      {group.members && Object.keys(group.members).length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Object.values(group.members).map((member, index) => (
            <div
              key={index}
              className={`p-4 border rounded-lg shadow-lg ${getStatusColor(
                group.memberStatus[member.creator.uid]
              )} ${
                member.creator.uid === group.creatorUID
                  ? "border-4 border-blue-500"
                  : ""
              }`}
            >
              <h4 className="text-xl font-bold mb-2">
                {member.creator.username}
              </h4>
              <p className="mb-1">
                <span className="font-semibold">Age:</span> {member.creator.age}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Gender:</span>{" "}
                {member.creator.gender}
              </p>
              <p className="mb-1">
                <span className="font-semibold">University:</span>{" "}
                {member.creator.university}
              </p>
              <p className="mb-1">
                <span className="font-semibold">UID:</span> {member.creator.uid}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Date of Ride:</span>{" "}
                {member.dateTime}
              </p>
              <a href={`/dashboard/users/${member.creator.uid}`}>
                <img
                  src={member.creator.profilePhoto}
                  alt={`${member.creator.username}'s profile`}
                  className="w-24 h-24 object-cover rounded-full mx-auto mb-4"
                />
              </a>
              <h5 className="text-lg font-semibold mb-2">Ride Details</h5>
              <p className="mb-1">
                <span className="font-semibold">Ride ID:</span>{" "}
                {member.creator.rideId}
              </p>
              <p className="mb-1">
                <span className="font-semibold">Pickup:</span>{" "}
                {member.pickup.address}
              </p>
              <p className="font-semibold mb-1">Dropoffs:</p>
              <ul className="list-disc list-inside ml-4 mb-4">
                {member.dropoffs.map((dropoff, idx) => (
                  <li key={idx}>
                    {dropoff.addressLong} (Postcode: {dropoff.postcodeShort})
                  </li>
                ))}
              </ul>
              <p className="mb-1">
                <span className="font-semibold">Member Status:</span>{" "}
                {group.memberStatus[member.creator.uid]}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p>No members in this group.</p>
      )}

      <h3 className="text-2xl font-semibold mt-6 mb-4">Ride IDs</h3>
      <ul className="list-disc list-inside ml-4">
        {Object.entries(group.rideIds).map(([rideId, role], index) => (
          <li key={index}>
            <span className="font-semibold">Ride ID:</span> {rideId}{" "}
            <span className="font-semibold">Role:</span> {role}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupDetails;
