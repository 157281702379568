import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  getRideDataById,
  deleteRideById,
  getCollectionData,
} from "../firebase/firestoreFunctions";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-polylinedecorator";

// Fix Leaflet's default icon issue with React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const RideDetailPage = () => {
  const { rideID } = useParams();
  const [rideData, setRideData] = useState(null);
  const [matches, setMatches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRideData = async () => {
      try {
        let rideData = await getRideDataById("Past_Rides", rideID);
        if (!rideData) {
          rideData = await getRideDataById("Active_Rides", rideID);
        }
        if (rideData) {
          setRideData(rideData);
          console.log("rideData", JSON.stringify(rideData));
        } else {
          throw new Error("Ride not found in both collections");
        }
      } catch (error) {
        console.error("Error fetching ride data:", error);
      }
    };

    const fetchMatchesData = async () => {
      try {
        let matchesData = await getCollectionData("Matches_Info", rideID);
        console.log("matchesData", JSON.stringify(matchesData));
        setMatches(matchesData || {}); // Set to empty array if matchesData is null
      } catch (error) {
        setMatches({});
        console.log("NOTHIGN FOUND");
        console.error("Error fetching matches data:", error);
      }
    };

    fetchRideData();
    fetchMatchesData();
  }, [rideID]);

  const handleDeleteRide = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this ride?"
    );
    if (confirmDelete) {
      try {
        await deleteRideById("Past_Rides", rideID);
        navigate("/dashboard/ridesList"); // Navigate back to rides list after deletion
      } catch (error) {
        console.error("Error deleting ride:", error);
      }
    }
  };

  const renderValue = (key, value) => {
    if (typeof value === "string" && value.startsWith("https://")) {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          <img src={value} alt={key} className="w-16 h-16 object-cover" />
        </a>
      );
    } else if (typeof value === "object" && value !== null) {
      return renderObject(value);
    } else if (typeof value === "boolean") {
      return value.toString();
    } else {
      return value !== null && value !== undefined ? value : "Missing";
    }
  };

  const renderObject = (obj) => (
    <div className="ml-4">
      {Object.entries(obj).map(([key, value]) => (
        <div className="flex justify-between py-2" key={key}>
          <span className="font-medium">{key.replace(/_/g, " ")}:</span>
          <span>{renderValue(key, value)}</span>
        </div>
      ))}
    </div>
  );

  if (!rideData) {
    return <p className="text-center text-gray-600 mt-4">Loading...</p>;
  }

  const isValidCoordinates = (lat, lng) => {
    const isValid =
      typeof lat === "number" &&
      typeof lng === "number" &&
      !isNaN(lat) &&
      !isNaN(lng);
    // console.log(
    //   `Validating coordinates lat: ${lat}, lng: ${lng} - ${
    //     isValid ? "Valid" : "Invalid"
    //   }`
    // );
    return isValid;
  };

  const pickupCoordinates =
    rideData?.pickup &&
    isValidCoordinates(rideData.pickup.latitude, rideData.pickup.longitude)
      ? [rideData.pickup.latitude, rideData.pickup.longitude]
      : null;

  // console.log(`Pickup Coordinates: ${pickupCoordinates}`);

  const dropoffCoordinates = rideData?.dropoffs
    ? Object.values(rideData.dropoffs)
        .map((dropoff) => {
          if (isValidCoordinates(dropoff.latitude, dropoff.longitude)) {
            return [dropoff.latitude, dropoff.longitude];
          }
          return null;
        })
        .filter((coords) => coords !== null)
    : [];

  // console.log(`Dropoff Coordinates: ${dropoffCoordinates}`);

  const polylineCoordinates = pickupCoordinates
    ? [pickupCoordinates, ...dropoffCoordinates]
    : [];

  // console.log(`Polyline Coordinates: ${polylineCoordinates}`);
  const MapComponent = () => {
    const map = useMap();

    if (pickupCoordinates && dropoffCoordinates.length > 0) {
      // Check if pickup and dropoff coordinates are identical
      const identicalCoordinates = dropoffCoordinates.some(
        (coords) => JSON.stringify(coords) === JSON.stringify(pickupCoordinates)
      );

      if (!identicalCoordinates) {
        const bounds = L.latLngBounds([
          pickupCoordinates,
          ...dropoffCoordinates,
        ]);
        map.fitBounds(bounds, { padding: [50, 50] });
      } else {
        // If pickup and dropoff have identical coordinates, return null
        return null;
      }
    } else if (pickupCoordinates) {
      map.setView(pickupCoordinates, 13);
    }

    return null;
  };

  const ArrowPolyline = () => {
    const map = useMap();
    if (polylineCoordinates.length > 1) {
      const polyline = L.polyline(polylineCoordinates, { color: "blue" }).addTo(
        map
      );
      const arrowDecorator = L.polylineDecorator(polyline, {
        patterns: [
          {
            offset: "100%",
            repeat: 0,
            symbol: L.Symbol.arrowHead({
              pixelSize: 15,
              pathOptions: { fillOpacity: 1, color: "blue" },
            }),
          },
        ],
      }).addTo(map);
    }
    return null;
  };

  const compatibleRides =
    matches &&
    matches.sameTimesRankedIDs &&
    matches.sameDropoffs &&
    matches.samePickupsPassed &&
    matches.allTimesRankedIDs
      ? [
          ...matches.sameTimesRankedIDs.map((id) => ({
            matchId: matches.id,
            rideId: id,
            tier: "Same Times Ranked",
          })),
          ...matches.sameDropoffs.map((id) => ({
            matchId: matches.id,
            rideId: id,
            tier: "Same Dropoffs",
          })),
          ...matches.samePickupsPassed.map((id) => ({
            matchId: matches.id,
            rideId: id,
            tier: "Same Pickups Passed",
          })),
          ...matches.allTimesRankedIDs.map((id) => ({
            matchId: matches.id,
            rideId: id,
            tier: "All Times Ranked",
          })),
        ]
      : [];

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar />
      <div className="flex-grow mx-4 overflow-y-auto">
        <div className="max-w-3xl mx-auto my-8 p-6 bg-white shadow-md rounded-md">
          <h1 className="text-2xl font-bold my-4">Ride Details</h1>

          <div className="flex justify-between items-center mb-4">
            <div>
              <p className="text-lg">
                <strong>Date and Time:</strong>{" "}
                {new Date(rideData.dateTime).toLocaleString()}
              </p>
              <p className="text-lg">
                <strong>Status:</strong> {rideData.status}
              </p>
            </div>
          </div>

          <div
            className="bg-gray-50 p-4 rounded-md shadow-inner mt-4"
            style={{ height: "400px" }}
          >
            <h3 className="text-lg font-semibold mb-3">Ride Map</h3>
            <MapContainer
              center={pickupCoordinates || [0, 0]}
              zoom={13}
              style={{ height: "100%", width: "100%" }}
              scrollWheelZoom={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {pickupCoordinates && (
                <Marker position={pickupCoordinates}>
                  <Popup>Pickup: {rideData.pickup.address}</Popup>
                </Marker>
              )}
              {dropoffCoordinates.map((coords, index) => (
                <Marker key={index} position={coords}>
                  <Popup>Dropoff: {rideData.dropoffs[index].address}</Popup>
                </Marker>
              ))}
              {pickupCoordinates && dropoffCoordinates.length > 0 && (
                <>
                  <Polyline positions={polylineCoordinates} color="blue" />
                  <ArrowPolyline />
                </>
              )}
              <MapComponent />
            </MapContainer>
          </div>

          <div className="bg-gray-50 p-4 rounded-md shadow-inner mt-4">
            <h3 className="text-lg font-semibold mb-3">Creator Information</h3>
            <p>
              <strong>Username:</strong> {rideData.creator.username}
            </p>
            <p>
              <strong>UID:</strong> {rideData.creator.uid}
            </p>
            {rideData.creator.profilePhoto && (
              <p>
                <strong>Profile Photo:</strong>{" "}
                <a href={`/dashboard/users/${rideData.creator.uid}`}>
                  <img
                    src={rideData.creator.profilePhoto}
                    alt="Profile"
                    className="w-32 h-32 object-cover rounded-full"
                  />
                </a>
              </p>
            )}
            <p>
              <strong>Gender:</strong> {rideData.creator.gender}
            </p>
            <p>
              <strong>Identity:</strong>{" "}
              {Object.keys(rideData.creator.identity).join(", ")}
            </p>
            {rideData.guests && rideData.guests.length > 0 && (
              <div>
                <strong>Guests:</strong>
                <div className="flex">
                  {rideData.guests.map((guest, index) => (
                    <img
                      key={index}
                      src={guest}
                      alt={`Guest ${index}`}
                      className="w-10 h-10 object-cover mr-2"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="bg-gray-50 p-4 rounded-md shadow-inner mt-4">
            <h3 className="text-lg font-semibold mb-3">Filters</h3>
            {Object.entries(rideData.filters).map(([key, value]) => (
              <p key={key}>
                <strong>{key.replace(/_/g, " ")}:</strong> {value.toString()}
              </p>
            ))}
          </div>

          <div className="bg-gray-50 p-4 rounded-md shadow-inner mt-4">
            <h3 className="text-lg font-semibold mb-3">Compatible Rides</h3>
            {compatibleRides.length > 0 ? (
              <div>
                {[
                  "Same Times Ranked",
                  "Same Dropoffs",
                  "Same Pickups Passed",
                  "All Times Ranked",
                ].map((tier) => (
                  <div key={tier}>
                    <h4 className="text-md font-semibold mt-3">{tier}</h4>
                    <ul>
                      {compatibleRides
                        .filter((ride) => ride.tier === tier)
                        .map((ride, index) => (
                          <li key={index}>
                            <a
                              href={`/dashboard/rides/${ride.rideId}`}
                              className="text-blue-500 underline"
                            >
                              Ride ID: {ride.rideId}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
              </div>
            ) : (
              <p>No compatible rides found.</p>
            )}
          </div>

          <button
            className="mt-6 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 flex items-center"
            onClick={handleDeleteRide}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            Delete Ride
          </button>
        </div>
      </div>
    </div>
  );
};

export default RideDetailPage;
